import React, { useEffect } from 'react'
import PropTypes from "prop-types";
import loadable from '@loadable/component'
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { kebabCase } from "lodash";
import { TrimStr } from "../components/Trim";
import Img from "gatsby-image"
import { Bar } from 'react-chartjs-2';
// import { Doughnut } from 'react-chartjs-2';

const ToC = loadable(() => import('../components/ToC'));
const BriefCall = loadable(() => import('../components/BriefCall'));
const PortfolioRoll2 = loadable(() => import('../components/PortfolioRoll2'));

export const PortfolioPostTemplate = ({
  id,
  content,
  industry,
  date,
  actions,
  targets,
  effects,
  contentComponent,
  description,
  tags,
  title,
  slug,
  headings,
  helmet,
  location,
  featuredimage,
  backgroundimage,
  chartslabel,
  labels,
  datas,
  mockupa,
  mockupb,
  mockupc,
  urla,
  urlb,
  urlc,
  author,
  testimonial,
  posts
}) => {
  const PostContent = contentComponent || Content;



  const legendOpts = {
    display: true,
    position: 'top',
    fullWidth: true,
    reverse: false,
    defaultFontColor: '#111111',
    labels: {
      fontColor: '#111111'
    },

  };
let sum
let backgrounds2 = []
let datas2 = []
let labels2 = []
let labelHolder = []
let backgrounds3 = []
let backgroundsHolder = []
let backgrounds = ['#72cae0','#4abbd6','#5ec2db','#36b3d2','#2ba4c2','#2792ad','#1d6d81','#185b6c','#1a6577','#134956','#1a6577','#185b6c','#1d6d81','#2792ad','#2ba4c2','#36b3d2','#5ec2db','#4abbd6','#72cae0']
let datasHolder = []

if (datas !== null && datas !== undefined){


for (let i=0;i<datas.length;i++){
  let striNum = datas[i].toString()
  striNum = striNum.slice(0,-3)+' '+striNum.slice(-3)
  datas2.push(striNum)
  datasHolder.push(datas[i])
}
for (let i=0;i<datas.length;i++){
  backgrounds3.push(backgrounds[i])
  backgroundsHolder.push(backgrounds[i])
}
for (let i=0;i<datas.length;i++){
  labels2.push(labels[i])
  labelHolder.push(labels[i])
}


for (let i=0;i<datas.length;i++){
  backgrounds2.push(backgrounds[i])
}


sum = datas.reduce(function (a, b) {
  return a + b;
}, 0);

sum = sum.toString()
sum = sum.slice(0,-3)+' '+sum.slice(-3)

labels = labelHolder
backgrounds2 = backgroundsHolder
labels.unshift(' ')
labels.push(' ')
backgrounds2.unshift('rgba(0,0,0,0)')
backgrounds2.push('rgba(0,0,0,0)')
datas = datasHolder
datas.unshift(' ')
datas.push(' ')


} else {
  sum = 0
}



  let dataset={
    labels: labels,
    datasets: [{
    label: chartslabel,
    borderWidth: 0,
    fill:'false',
    data: datas,
    backgroundColor: backgrounds2,
    hoverBackgroundColor: '#111111',defaultFontColor: '#111111',
  }],
  }


  return (
    <>
      <section>
        {helmet}
        <div id="json"></div>
        {backgroundimage ?
          backgroundimage.childImageSharp ?
        <Img fluid={backgroundimage.childImageSharp.fluid}
         alt={title}
         title={title}
         loading={'eager'}
         style={{height: "350px"}}
         imgStyle={{ height: "350px",
           width: "100%",
           backgroundColor: "black",
           filter: "brightness(0.3)",
           zIndex: '1000'}}
         />
         :
         <img src={backgroundimage}
          alt={title}
          title={title}
          style={{height: "350px",
            width: "100%",
            backgroundColor: "black",
            filter: "brightness(0.3)",
            zIndex: '1000'}}
          /> :
          <div style={{height: "350px",
             width: "100%",
             backgroundColor: "black",
             zIndex: '1000'}}
           > </div>
        }

        <div
          style={{
            position: "relative",
            textAlign: "center",
            top: "-300px",
            width: "100%",
            display: "block",
            zIndex: '2000'
          }}
        >
          <div style={{ margin: "auto" }}>
          {featuredimage ?
            featuredimage.childImageSharp ?
          <Img fluid={featuredimage.childImageSharp.fluid}
           alt={title}
           title={title}
           style={{height:'150px',overflow:'visible'}}
           imgStyle={{ border: "3px solid black",
           padding: "0px 20px",
           borderRadius: "10px",
           maxWidth:'250px',
           height:'90%',
           objectFit:'contain',
           top:'30px',
           left: 'calc(50% - 125px)',
           backgroundColor: 'white',zIndex:'3000',objectPosition:'center center'}}
           />
           :
           <img src={featuredimage}
            alt={title}
            title={title}
            style={{border: "3px solid black",
            padding: "0px 20px",
            borderRadius: "10px",
            maxWidth:'250px',
            height:'90%',
            objectFit:'contain',
            top:'30px',
            left: 'calc(50% - 125px)',
            backgroundColor: 'white',zIndex:'3000',objectPosition:'center center'}}
            /> : null
           }
            <br />
            <br />
            <h1
              className="title is-size-5"
              style={{ textAlign: "center", color: "white" }}
            >
              {description}
              <br />
              {/*<span className="ofs">{category}</span>*/}
            </h1>
            <h2
              className="title"
              style={{ textAlign: "center", color: "#36b3d2", fontSize: "1em" }}
            >
              Branża: <span style={{ color: "white" }}>{industry}</span>
            </h2>
          </div>
        </div>

        <div className="container content" style={{ marginTop: "-150px" }}>
          {/* Treść */}
          <div>
            <div className="columns">
              <div className="column notification box">
                <div style={{ textAlign: "center" }}>
                  <img
                    className="atut"
                    loading="lazy"
                    src="/img/fa-icons/bullseye-solid.svg"
                    style={{ margin: "10px" }}
                    width="50px"
                    height="50px"
                    alt="zespół doświadczonych copywriterów znających SEO i e-commerce"
                  />
                </div>
                <h3 style={{ textAlign: "center", marginTop: "0px" }}>Cele:</h3>
                <br />
                {/* CMS tak jak tagi x 3 */}
                <ul>
                {targets && targets.map(target => (
                  <>
                  <li style={{paddingBottom:'15px'}} key={target}>
                      {target}
                  </li>

                  </>
                ))}

                </ul>

              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="column notification box">
                <div style={{ textAlign: "center" }}>
                  <img
                    className="atut"
                    loading="lazy"
                    src="/img/fa-icons/directions-solid.svg"
                    style={{ margin: "10px" }}
                    width="50px"
                    height="50px"
                    alt="zespół doświadczonych copywriterów znających SEO i e-commerce"
                  />
                </div>
                <h3 style={{ textAlign: "center", marginTop: "0px" }}>
                  Zakres działań:
                </h3>
                <br />
                <ul>
                {actions && actions.map(action => (
                  <>
                  <li style={{paddingBottom:'15px'}} key={action}>
                      {action}
                  </li>

                  </>
                ))}

                </ul>

              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div
                className="column notification box"
                style={{ marginBottom: "1.5rem" }}
              >
                <div style={{ textAlign: "center" }}>
                  <img
                    className="atut"
                    loading="lazy"
                    src="/img/fa-icons/calendar-check-solid.svg"
                    style={{ margin: "10px" }}
                    width="50px"
                    height="50px"
                    alt="zespół doświadczonych copywriterów znających SEO i e-commerce"
                  />
                </div>
                <h3 style={{ textAlign: "center", marginTop: "0px" }}>
                  Efekty:
                </h3>
                <br />
                <ul>
                {effects && effects.map(effect => (
                  <>
                  <li style={{paddingBottom:'15px'}} key={effect}>
                      {effect}
                  </li>
                  </>
                ))}
                </ul>

              </div>
            </div>

            <div
              className="column is-10 is-offset-1"
              style={{ marginTop: "40px",marginBottom:'40px' }}
            >
              <PostContent content={content} />
            </div>
            </div>
            </div>



            <div>
            {/*Wykresy*/}
            {chartslabel && datas && labels ? (
            <div style={{minHeight:'400px',paddingLeft:'10%',paddingRight:'10%',paddingTop:'5%',paddingBottom:'5%',marginTop:'50px',backgroundColor:'#f5f5f5'}}>
            <h3 className="title" style={{marginLeft:'-5%'}}>Efekty naszej pracy w liczbach
            <br />
            <sub style={{fontSize:'.7em'}}>Szczegółowe dane widoczne po najechaniu kursorem na kolumnę</sub>
            </h3>
            <br />
            <div className="columns">
            <div className="column"  style={{height:'90%',width:'90%'}}>
            <Bar
              data={dataset}
              width={100}
              height={70}
              options={{ maintainAspectRatio: true, fontColor: '#111111',
                      scales:{
                          xAxes: [{
                              display: false //this will remove all the x-axis grid lines
                          }]
                      },
              }}
              legend={legendOpts}
            />
            </div>
            &nbsp; &nbsp; &nbsp;

            <div className="column" style={{width:'90%',height:'90%'}} >
              {/*<Doughnut
                data={dataset}
                width={100}
                height={70}
                options={{ maintainAspectRatio: true, fontColor: '#111111'}}
                legend={legendOpts}
                ></Doughnut>*/}

                <p><b>Legenda:</b></p>
                <br />
                <div className="columns" style={{width:'fit-content',display:'flex'}}>
                  <div style={{padding:'12px', width:'min-content'}}>
                  {backgrounds3 && backgrounds3.length ? (
                      <ul className="legend">
                        {backgrounds3.map(label => (
                            <li style={{height:'25px',width:'50px',backgroundColor:`${label}`}}></li>
                        ))}
                      </ul>
                  ) : null}
                  </div>
                  <div className="column">
                  {labels2 && labels2.length ? (
                      <ul className="legend" style={{width:'max-content'}}>
                        {labels2.map(label => (
                            <li style={{height:'25px'}} key={label}>  {label} </li>
                        ))}
                      </ul>
                  ) : null}
                  </div>
                  <div className="column">
                  {datas2 && datas2.length ? (
                      <ul className="legend" style={{minWidth:'max-content'}}>
                        {datas2.map(label => (
                            <li style={{height:'25px'}} key={label}>  {label} </li>
                        ))}
                      </ul>
                  ) : null}
                  </div>
                </div>
                {sum !== 0 ? (<><p> Łączna ilość opisów: <b>{sum}</b></p></>) : null}
            </div>


            </div>
            </div>
            ) : null}
            {/*Mockupy*/}




            <div style={{width:'100%',marginLeft:'0%',padding:'5%',color: '#050505',backgroundColor:'#36b3d2',marginBottom:'50px'}}>
            <h3 className="title" style={{color:'white'}}>Zrealizowane treści dla klienta:</h3>
            <div className="columns" style={{flexWrap:'wrap',justifyContent:'center'}}>



            <div className="column mocka" style={{alignSelf:'flex-end',minWidth:'500px',maxWidth:'600px'}}>
            {/*Laptop*/}
            <div style={{height: "420px",width: "100%",backgroundColor: "white",borderRadius: "15px 15px 0px  0px",padding: "10px",margin: "auto",textAlign: "center",}}>
            <div style={{height: "10px",display: "block",margin: "auto",width: "10px",borderRadius: "50%",backgroundColor: "rgb(200,200,200)",marginTop: "10px",zIndex: '10'}}>
            </div>
            {mockupa ?
              mockupa.childImageSharp ?
            <Img className="mocka-img" fluid={mockupa.childImageSharp.fluid}
             alt={title}
             title={title}
             style={{height:"380px",marginTop: "10px",overflow: "hidden",borderRadius: "15px",border:'5px solid lightgray',
             backgroundImage:'url(/img/zobacz-sam.svg)',
             backgroundPosition:'center',backgroundSize:'cover'}}
             imgStyle={{
               width: "100%",
               backgroundColor: "black",objectPosition:'top center'}}
             />
             :
             <div style={{height:"380px",marginTop: "10px",overflow: "hidden",borderRadius: "15px",border:'5px solid lightgray',
            backgroundImage:mockupa ? (`url(${mockupa})`) : 'url(/img/zobacz-sam.svg)',
            backgroundPosition:'center',backgroundSize:'cover'}}>
            </div> :
            <div style={{height:"380px",marginTop: "10px",overflow: "hidden",borderRadius: "15px",border:'5px solid lightgray',
           backgroundImage:mockupa ? (`url(${mockupa})`) : 'url(/img/zobacz-sam.svg)',
           backgroundPosition:'center',backgroundSize:'cover'}}>
           </div>
            }
            </div>

            <div style={{height: "40px",width: "110%",margin: "auto",marginLeft:'-5%',marginTop: "0px",backgroundColor: "#f4f4f4",borderRadius: "5px 5px 0px 0px",}}></div>
            <div style={{height: "5px",width: "110%",margin: "auto",marginLeft:'-5%',marginTop: "0px",backgroundColor: "rgb(200,200,200)",borderRadius: "0px 0px 20px 20px",}}></div>
            <div style={{textAlign:'center',position:'relative',display:'block'}}>
            <br />
            <a style={{margin:'auto'}} target="_blank" rel="noopener noreferrer" className="btn" href={urla ? urla : '#'}> ZOBACZ </a>
            </div>
            </div>


            <div className="column mockb" style={{alignSelf:'flex-end',minWidth:'500px',maxWidth:'600px'}}>
            {/*Tablet*/}
              <div style={{ height: "450px", width: "80%",backgroundColor: "white", borderRadius: "25px 25px 0px 0px", padding: "10px",margin: "auto",textAlign: "center",}}>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", margin: "auto" }}>
                <div
                  style={{height: "10px",display: "block",margin: "auto",width: "10px",borderRadius: "50%",border: "1px solid rgb(180,180,180)",marginTop: "10px",}}></div>
                &nbsp;&nbsp;
                <div
                  style={{height: "10px",display: "block",margin: "auto",width: "10px",borderRadius: "50%",border: "1px solid rgb(180,180,180)",marginTop: "10px",}}>
                </div>
              </div>
              <div style={{height: "10px",display: "block",margin: "auto",width: "33%",borderRadius: "5px 0px",backgroundColor: "rgb(220,220,220)",marginTop: "10px",}}>
              </div>
              <div style={{ display: "block", margin: "auto" }}>
                <div style={{height: "20px",width: "20px",borderRadius: "50%",backgroundColor: "rgb(200,200,200)",marginTop: "5px",}}>
                </div>
              </div>
            </div>
            {mockupb ?
              mockupb.childImageSharp ?
            <Img className="mockb-img"  fluid={mockupb.childImageSharp.fluid}
             alt={title}
             title={title}
             style={{height:"350px",marginTop: "10px",overflow: "hidden",borderRadius: "15px",border:'5px solid lightgray',backgroundImage:'url(/img/zobacz-sam.svg)',backgroundPosition:'center',backgroundSize:'cover'}}
             imgStyle={{
               width: "100%",
               backgroundColor: "black",objectPosition:'top center'}}
             />
             :
             <div style={{height:"350px",marginTop: "10px",overflow: "hidden",borderRadius: "15px",border:'5px solid lightgray',backgroundImage:mockupb ? (`url(${mockupb})`) : 'url(/img/zobacz-sam.svg)',backgroundPosition:'center',backgroundSize:'cover'}}>
             </div> :
             <div style={{height:"350px",marginTop: "10px",overflow: "hidden",borderRadius: "15px",border:'5px solid lightgray',backgroundImage:mockupb ? (`url(${mockupb})`) : 'url(/img/zobacz-sam.svg)',backgroundPosition:'center',backgroundSize:'cover'}}>
             </div>
            }

            <div style={{height: "30px",display: "block",margin: "auto",width: "30px",border: "1px solid rgb(170,170,170)",borderRadius: "50%",marginTop: "10px",}}>
            </div>
          </div>
          <div style={{height: "25px",width: "80%",margin: "auto",marginTop: "-1px",backgroundColor: "#f4f4f4",borderRadius: "0px 0px 25px 25px",}}></div>
          <div style={{textAlign:'center',position:'relative',display:'block'}}>
          <br />
          <a style={{margin:'auto'}} target="_blank" rel="noopener noreferrer" className="btn" href={urlb ? urlb : '#'}> ZOBACZ </a>
          </div>
          </div>



            <div className="column mockc" style={{alignSelf:'flex-end',minWidth:'500px',maxWidth:'600px'}}>
            <div style={{height:'475px',width:'110%',backgroundColor:'white',borderRadius:'15px',padding:'5px',marginLeft:'-5%',zIndex:'2'}}>
            {mockupc ?
              mockupc.childImageSharp ?
            <Img className="mockc-img"  fluid={mockupc.childImageSharp.fluid}
             alt={title}
             title={title}
             style={{height:"375px",marginTop: "10px",overflow: "hidden",borderRadius: "15px",border:'5px solid lightgray',padding:'5px',backgroundImage:'url(/img/zobacz-sam.svg)',backgroundPosition:'center',backgroundSize:'cover'}}
             imgStyle={{
               width: "100%",
               backgroundColor: "black",objectPosition:'top center'}}
             />
             :
             <div style={{height:"375px",marginTop: "10px",overflow: "hidden",borderRadius: "15px",border:'5px solid lightgray',padding:'5px',backgroundImage:mockupc ? (`url(${mockupc})`) : 'url(/img/zobacz-sam.svg)',backgroundPosition:'center',backgroundSize:'cover'}}>
            </div> :
            <div style={{height:"375px",marginTop: "10px",overflow: "hidden",borderRadius: "15px",border:'5px solid lightgray',padding:'5px',backgroundImage:mockupc ? (`url(${mockupc})`) : 'url(/img/zobacz-sam.svg)',backgroundPosition:'center',backgroundSize:'cover'}}>
           </div>
            }
                <div style={{height: "40px",display: "block",margin: "auto",width: "40px",border: "1px solid rgb(170,170,170)",borderRadius: "50%",marginTop: "10px",}}></div>
              </div>
                <div style={{height:'40px', width:'15%',marginLeft:'42.5%',marginTop:'0px',backgroundColor:'#f4f4f4',borderRadius:'0px',}}></div>
                <div style={{height:'25px ', width:'25%',marginLeft:'37.5%',marginTop:'0px',backgroundColor:'white',borderRadius:'20px',}}></div>
            <div style={{textAlign:'center',position:'relative',display:'block'}}>
              <br />
              <a style={{margin:'auto'}} target="_blank" rel="noopener noreferrer" className="btn" href={urlc ? urlc : '#'}> ZOBACZ </a>
            </div>
            </div>
            </div>

            </div>



            <div className="container content">

            {/* Testimonial*/}
            {testimonial ? (
            <div className="box" style={{textAlign:"left",marginTop:'30px',width:'80%',marginLeft:'10%',marginBottom:'70px'}}>
            <i><q>{testimonial}</q></i>
            <br></br><br></br>
            <p align="right" style={{color:'#36b3d2',fontSize:'1.2em'}}><sup>*</sup><i>{author}</i></p>
            </div> ) : null }


            <div
              className="column is-10 is-offset-1"
              style={{ marginTop: "20px" }}
            >
            <p className="title">Zobacz inne realizacje:</p>

            <PortfolioRoll2 posts={posts} />


            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tagi:</h4>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `- tag`}>
                      <Link
                        className="btn"
                        to={`/tagi/${kebabCase(tag)}/`}
                        style={{ padding: "10px", fontSize: "12px" }}
                      >
                        {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}

            </div>
          </div>
        </div>
      </section>


    </>
  );
};







PortfolioPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  slug: PropTypes.string,
  industry: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  headings: PropTypes.object,
  id: PropTypes.string
};

const PortfolioPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const posts = data.allMarkdownRemark.edges

  let filterPosts = []
  posts.forEach((item, i) => {
    if(item.node.frontmatter.title !== post.frontmatter.title) {
      filterPosts.push(item)
    }
  });


    let cats = []

    posts.forEach(edge => {
      if(!cats.includes(edge.node.frontmatter.industry)){
      cats.push(edge.node.frontmatter.industry)
      }
    })

  useEffect(() => {
    if(post.frontmatter.english){
     document.getElementById('lang-switch').href = post.frontmatter.english
   }
   });


  let mockupa = post.frontmatter.mockupa
  let mockupb = post.frontmatter.mockupb
  let mockupc = post.frontmatter.mockupc

  const blogPostingSchemaOrgJSONLD = {
    "@context": "http://schema.org",
    "@type": "BlogPosting",
    url: "https://e-multicontent.pl" + post.fields.slug,
    name: post.frontmatter.title.substring(0, 60),
    alternateName: post.frontmatter.title.substring(0, 60) || "",
    headline: post.frontmatter.description.substring(0, 120),
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://e-multicontent.pl" + post.fields.slug
    },
    author: {
      "@type": "Organization",
      name: "Zespół e-multiconent.pl",
      url: "https://e-multicontent.pl/o-nas/"
    },
    image: {
      "@type": "ImageObject",
      url:
        "https://e-multicontent.pl" + post.frontmatter.featuredimage.publicURL
    },
    datePublished: post.frontmatter.date,
    dateModified: post.frontmatter.date,
    publisher: {
      "@type": "Person",
      name: post.frontmatter.author,
      logo: {
        "@type": "ImageObject",
        url: "https://e-multicontent.pl/icons/icon-512x512.png"
      }
    },
    description: post.frontmatter.description.substring(0, 145) + "..."
  };

  return (
    <Layout>
    <nav className="breadcrumbs-nav section" style={{zIndex:'2300'}}>
        <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<Link className="inline-link" to="/portfolio/">Portfolio</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600',color:'white'}}><b>{post.frontmatter.title}</b></span>
    </nav>
    <div id="portfolio-desktop-nav" className="rows ptf-nav" style={{
      color: 'rgb(23,19,43)',
      lineHeight: '1',
      padding: '10px',
      borderRadius: '20px',
      backgroundColor: 'rgba(255,255,255,0.9)',
      position: 'fixed',
      right: '0%',
      overflow:'scroll',
      maxHeight: '335px',
      top: '55px',
      zIndex: '20000',
      textAlign: 'right',
      width:'min-content',
    }}>
    <p style={{fontSize:'10px'}} align="center"><u> Kategorie:</u></p><br></br>
    {cats.sort().reverse().map(cat => {
      return (
        <>
        {cat === post.frontmatter.industry ?
        <Link key={TrimStr(cat)} className='pnav btn active' style={{marginBottom:'2px',width:'100%',fontSize:'10px'}} to={'/portfolio/'+TrimStr(cat)+'/'} >
          {cat}
        </Link>
        :
        <Link key={TrimStr(cat)} className='pnav btn' style={{marginBottom:'2px',width:'100%',fontSize:'10px'}} to={'/portfolio/'+TrimStr(cat)+'/'} >
          {cat}
        </Link>
        }
        </>
      )
    })}
    </div>
      <PortfolioPostTemplate
        content={post.html.replace(/href/g, "rel='noopener noreferrer' target='_blank' href")}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | e-multiconent.pl">
            <title>
              {`${'Współpraca z marką '+post.frontmatter.title.substring(0, 60)}`}
            </title>
            <meta
              name="description"
              content={`${post.frontmatter.description.substring(0, 145) +
                "..."}`}
            />
            <meta
              name="image"
              content={
                "https://e-multicontent.pl" +
                post.frontmatter.featuredimage.publicURL
              }
            />

            {/* Schema.org tags */}
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Portfolio",
                    item: "https://e-multicontent.pl/portfolio/"
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: post.frontmatter.title,
                    item:
                      "https://e-multicontent.pl/portfolio/" +
                      TrimStr(post.frontmatter.title) +
                      "/"
                  }
                ]
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify(blogPostingSchemaOrgJSONLD)}
            </script>

            {/* OpenGraph tags */}
            <meta
              property="og:title"
              content={post.frontmatter.title.substring(0, 60)}
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:description"
              content={post.frontmatter.description.substring(0, 145) + "..."}
            />
            <meta
              property="og:image"
              content={
                "https://e-multicontent.pl" +
                post.frontmatter.featuredimage.publicURL
              }
            />
            <meta
              property="og:url"
              content={"https://e-multicontent.pl" + post.fields.slug}
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="e-multicontent.pl" />
            <meta
              property="twitter:url"
              content={"https://e-multicontent.pl" + post.fields.slug}
            />
            <meta
              name="twitter:title"
              content={post.frontmatter.title.substring(0, 60)}
            />
            <meta
              name="twitter:description"
              content={post.frontmatter.description.substring(0, 145) + "..."}
            />
            <meta
              name="twitter:image"
              content={
                "https://e-multicontent.pl" +
                post.frontmatter.featuredimage.publicURL
              }
            />
            <link rel="alternate" href={post.frontmatter.english} hrefLang="en-gb" />
            <link rel="alternate" href={post.frontmatter.english} hrefLang="en-us" />
            <link rel="canonical" href={"https://e-multicontent.pl" + post.fields.slug}  />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        targets={post.frontmatter.targets}
        actions={post.frontmatter.actions}
        effects={post.frontmatter.effects}
        testimonial={post.frontmatter.testimonial}
        author={post.frontmatter.author}
        title={post.frontmatter.title}
        industry={post.frontmatter.industry}
        chartslabel={post.frontmatter.chartslabel}
        datas={post.frontmatter.datas}
        labels={post.frontmatter.labels}
        featuredimage={post.frontmatter.featuredimage}
        backgroundimage={post.frontmatter.backgroundimage}
        mockupa={mockupa ? post.frontmatter.mockupa : null}
        mockupb={mockupb ? post.frontmatter.mockupb : null}
        mockupc={mockupc ? post.frontmatter.mockupc : null}
        urla={post.frontmatter.urla}
        urlb={post.frontmatter.urlb}
        urlc={post.frontmatter.urlc}
        slug={post.fields.slug}
        id={post.id}
        posts={filterPosts}
        headings={<ToC headings={post.headings} />
        }
      />
      <div className="container content is-offset-1" style={{textAlign:'center',marginTop:'50px',marginBottom:'50px'}}>


        <Link
          className="btn"
          style={{ fontSize: "1.1em" }}
          to="/portfolio/"
        >
          Portfolio &nbsp; &nbsp;
          <img
            loading="lazy"
            width="15px" height="22px"
            style={{ verticalAlign: "middle", marginRight: "5px" }}
            alt="brief"
            src="/img/angle-right.svg"
          />
        </Link>
        &nbsp; &nbsp;
        &nbsp; &nbsp;
        <Link
          className="btn"
          style={{ fontSize: "1.1em" }}
          to="/"
        >
          Home &nbsp; &nbsp;
          <img
            loading="lazy"
            width="15px" height="22px"
            style={{ verticalAlign: "middle", marginRight: "5px" }}
            alt="brief"
            src="/img/angle-right.svg"
          />
        </Link>
        &nbsp; &nbsp;
        &nbsp; &nbsp;
        <Link
          className="btn"
          style={{ fontSize: "1.1em" }}
          to="/kontakt/"
        >
          Kontakt &nbsp; &nbsp;
          <img
            loading="lazy"
            width="15px" height="22px"
            style={{ verticalAlign: "middle", marginRight: "5px" }}
            alt="brief"
            src="/img/angle-right.svg"
          />
        </Link>

      </div>
      <BriefCall />
    </Layout>
  );
};

PortfolioPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }),
  location: PropTypes.shape({}).isRequired
};

export default PortfolioPost;

export const pageQuery = graphql`
  query PortfolioPost($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      headings {
        value
        depth
      }
      html
      frontmatter {
        date(formatString: "DD. MM. YYYY")
        title
        english
        industry
        description
        tags
        targets
        actions
        effects
        testimonial
        author
        chartslabel
        labels
        datas
        mockupa {
          childImageSharp {
            fluid(maxWidth: 1000) {
              srcWebp
              src
              srcSet
              srcSetWebp
            }
          }
          publicURL
        }
        urla
        mockupb {
          childImageSharp {
            fluid(maxWidth: 1000) {
              srcWebp
              src
              srcSet
              srcSetWebp
            }
          }
          publicURL
        }
        urlb
        mockupc {
          childImageSharp {
            fluid(maxWidth: 1000) {
              srcWebp
              src
              srcSet
              srcSetWebp
            }
          }
          publicURL
        }
        urlc
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              srcWebp
              src
              srcSet
              srcSetWebp
            }
          }
          publicURL
        }
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              srcWebp
              src
              srcSet
              srcSetWebp
            }
          }
          publicURL
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "portfolio-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            industry
            templateKey
            date(formatString: "DD. MM. YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  srcWebp
                  src
                  srcSet
                  srcSetWebp
                }
              }
              publicURL
            }
            backgroundimage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  srcWebp
                  src
                  srcSet
                  srcSetWebp
                }
              }
              publicURL
            }
            tags
          }
        }
      }
    }
  }
`;
